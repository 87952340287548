import { Button, DownloadField, Icon, useCrudContext } from '@guillotinaweb/react-gmi'
import { useEffect, useRef, useState } from 'react'

import { get } from 'helpers/utils'
import { vocabulariesGetLabelByKey } from 'helpers/vocabulariesMapKeyLabels'
import { LinkField } from './LinkField'
import { RenderColor } from './RenderColor'
import { RenderDateStartEnd } from './RenderDateStartEndField'
import { RenderField } from './RenderField'
import { RenderVocabularyDynamic } from './RenderVocabularyDynamic'

export const DEFAULT_VALUE_EDITABLE_FIELD = 'Click to edit'
export const DEFAULT_VALUE_NO_EDITABLE_FIELD = ' -- '
const sizesImages = ['high', 'large', 'preview', 'mini', 'thumb', 'tile', 'icon']

const getValueRenderer = (value) => {
  const getResult = (val) => {
    return vocabulariesGetLabelByKey(val)
  }
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.map((val) => {
        return getResult(val)
      })
    }
    return value
  } else {
    return getResult(value)
  }
}

export function EditableField({
  field,
  value,
  ns,
  schema = undefined,
  modifyContent,
  required,
  idLinkField,
}) {
  const ref = useRef()
  const [isEdit, setEdit] = useState(false)
  const [val, setValue] = useState(value)
  const { patch, loading, Ctx } = useCrudContext()
  const [isLoading, setIsLoading] = useState(false)

  const EditComponent = Ctx.registry.get('components', 'EditComponent')

  useEffect(() => {
    if (isEdit && ref.current) {
      ref.current.focus()
    }
  })

  const canModified = modifyContent && !get(schema, 'readonly', false)
  const haveDeleteBtn =
    schema?.widget === 'file' || schema?.widget === 'select' || schema?.type === 'array'

  const getRenderProps = () => {
    const renderProps = {
      schema: schema,
      value:
        getValueRenderer(val) ??
        (modifyContent ? DEFAULT_VALUE_EDITABLE_FIELD : DEFAULT_VALUE_NO_EDITABLE_FIELD),
    }
    if (idLinkField) {
      renderProps['value'] = value
      renderProps['Widget'] = LinkField
    } else if (val && schema?.widget === 'file') {
      renderProps['value'] = {
        data: val,
        field: field,
      }
      renderProps['Widget'] = DownloadField
    } else if (schema.widget === 'color') {
      renderProps['Widget'] = RenderColor
    } else if (schema.widget === 'date-start-end') {
      renderProps['value'] = value
      renderProps['Widget'] = RenderDateStartEnd
    } else if (schema?.type === 'boolean') {
      renderProps['value'] = val?.toString() ?? renderProps['value']
    } else if (val && schema?.type === 'datetime') {
      renderProps['value'] = new Date(val).toLocaleString()
    } else if (
      get(schema, 'items.widget', null) === 'vocabularyDynamic' ||
      get(schema, 'widget', null) === 'vocabularyDynamic'
    ) {
      renderProps['Widget'] = RenderVocabularyDynamic
    }
    return renderProps
  }

  const saveField = async (ev) => {
    if (ev) ev.preventDefault()

    if (!field) {
      Ctx.flash(`Provide a key name!`, 'danger')
      return
    }

    if (!val && required) {
      Ctx.flash(`${field} is mandatory!`, 'danger')
      return
    }

    if (schema?.widget === 'file') {
      setIsLoading(true)
      const value = val
      if (value) {
        value['filename'] = unescape(encodeURIComponent(value['filename']))
      }
      const endpoint = `${Ctx.path}@upload/${field}`
      const req = await Ctx.client.upload(endpoint, value)

      if (req.status !== 200) {
        Ctx.flash(`Failed to upload file ${field}!`, 'danger')
        setIsLoading(false)
        return
      }

      if (schema?.file_type === 'image') {
        for (let i = 0; i < sizesImages.length; i++) {
          const endpointSize = `${Ctx.path}@images/${field}/${sizesImages[i]}`
          let hasError = false
          try {
            const req = await Ctx.client.upload(endpointSize, value)
            if (req.status !== 200) hasError = true
          } catch (err) {
            console.log(err)
            hasError = true
          }

          if (hasError) {
            Ctx.flash(`Failed to upload file ${field}/${sizesImages[i]}!`, 'danger')
            setIsLoading(false)
            return
          }
        }
      }

      setIsLoading(false)
      Ctx.flash(`${field} uploaded!`, 'success')
    } else {
      const data = ns ? { [ns]: { [field]: val } } : { [field]: val }
      const dataPatch = await patch(data)
      if (dataPatch.isError) {
        Ctx.flash(`Error in field ${field}!`, 'danger')
      } else {
        Ctx.flash(`Field ${field}, updated!`, 'success')
      }
    }

    setEdit(false)
    Ctx.refresh()
  }

  const deleteField = async (ev) => {
    if (ev) ev.preventDefault()
    if (schema?.widget === 'file') {
      if (!field || (!val && required)) {
        Ctx.flash(`You can't delete ${field}!`, 'danger')
        return
      }

      const data = ns ? { [ns]: { [field]: null } } : { [field]: null }
      const dataPatch = await patch(data)
      if (dataPatch.isError) {
        Ctx.flash(`Error in field ${field}!`, 'danger')
      } else {
        Ctx.flash(`Field ${field}, deleted!`, 'success')
      }

      setEdit(false)
      Ctx.refresh()
    } else if (schema?.type === 'string' && schema?.enum) {
      setValue(null)
    } else if (schema?.type === 'array' && schema?.items.type === 'string') {
      setValue([])
    }
  }
  return (
    <>
      {!isEdit && (
        <div
          className={canModified ? 'editable' : ''}
          onClick={() => {
            setEdit(!!canModified)
          }}
          data-test={`editableFieldTest-${field}`}
        >
          <RenderField {...getRenderProps()} />
          {canModified && <Icon icon="fas fa-edit" />}
        </div>
      )}

      {isEdit && (
        <div className="field" data-test={`editableFieldTest-${field}`}>
          <div className="control">
            <EditComponent
              ref={ref}
              schema={schema}
              val={val}
              setValue={setValue}
              dataTest={`editableFieldEditTest`}
            />
          </div>
          <div className="field is-grouped">
            <div className="control">
              <Button
                className="is-small is-primary"
                loading={loading || isLoading}
                onClick={saveField}
                dataTest="editableFieldBtnSaveTest"
              >
                Save
              </Button>
            </div>
            <div className="control">
              <Button
                loading={loading || isLoading}
                className="is-small"
                onClick={() => setEdit(false)}
                dataTest="editableFieldBtnCancelTest"
              >
                Cancel
              </Button>
            </div>
            {!required && haveDeleteBtn && (
              <div className="control">
                <Button
                  loading={loading || isLoading}
                  className="is-small is-danger"
                  onClick={deleteField}
                  dataTest="editableFieldBtnDeleteTest"
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
