import { Editor } from '@tinymce/tinymce-react'
import { Spin } from 'antd'
import { Fragment, useState } from 'react'

export const RichText = ({ val, setValue, id, placeholder }) => {
  const [loading, setLoading] = useState(true)
  return (
    <Fragment>
      {loading && (
        <div className="p-4">
          <Spin />
        </div>
      )}
      <label className="label" htmlFor={id}>
        {placeholder}
      </label>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        value={val}
        onInit={() => setLoading(false)}
        init={{
          height: 600,
          menubar: 'table',
          plugins:
            'advlist anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss insertdatetime link lists media nonbreaking pagebreak preview quickbars save searchreplace table template tinydrive visualblocks visualchars wordcount',
          toolbar:
            'undo redo | styles | bold italic forecolor backcolor fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help code link',
        }}
        onEditorChange={(content) => {
          setValue(content)
        }}
      />
    </Fragment>
  )
}
