import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'

import { Form, useTraversal } from '@guillotinaweb/react-gmi'
import { EditComponent } from 'components/Fields'

const orderFieldsForm = ['title', 'titol_idiomes', 'subtitol_idiomes', 'descripcio_idiomes']
const requiredFields = ['title']

export const FestivalForm = ({ onSubmit, title, loading, dataTest, actionName }) => {
  const traversal = useTraversal()

  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
    formFields: [],
  })

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Festival')
          setSchema({
            loading: false,
            data: dataJson,
          })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const submit = () => {
    const currentErrors = {}

    requiredFields.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        currentErrors[key] = 'This field is required'
      }
    })

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return
    }

    onSubmit({ ...formData })
  }

  return (
    <Form title={title} onSubmit={submit} dataTest={dataTest}>
      {schema &&
        schema.data &&
        !schema.loading &&
        orderFieldsForm.map((key) => {
          const value = schema.data.properties[key]
          return (
            <EditComponent
              key={key}
              id={key}
              schema={value}
              val={key in formData ? formData[key] : ''}
              placeholder={value?.title ?? ''}
              required={requiredFields.includes(key)}
              setValue={(ev) => {
                setFormData({ ...formData, [key]: ev })
              }}
              error={errors[key]}
              dataTest={`${key}TestInput`}
            />
          )
        })}

      <div className="level level-right">
        <button
          type="submit"
          className={`button is-success ${loading ? 'is-loading' : ''}`}
          data-test="formBaseBtnTestSubmit"
        >
          {actionName || 'Add'}
        </button>
      </div>
    </Form>
  )
}
