import jwtDecode from 'jwt-decode'
export class AuthToken {
  constructor(url, token = null, logged = undefined) {
    this.state = {
      token: token,
      logged: logged,
    }
    this.guillotina = url
    this.errors = undefined
  }

  setState(values) {
    this.state = values
  }

  getUrl(endpoint) {
    return `${this.url}${endpoint}`
  }

  _getToken() {
    return [localStorage.getItem('auth'), localStorage.getItem('auth_expires')]
  }

  getToken() {
    const [token] = this._getToken()
    return token
  }

  get isLogged() {
    if (typeof localStorage === 'undefined') {
      return false
    }
    const [token, expires] = this._getToken()
    if (!token || !expires) {
      return false
    }
    if (this.isExpired(expires)) {
      return false
    }
    return true
  }

  get username() {
    if (typeof localStorage === 'undefined') {
      return false
    }
    const token = this.getToken()
    const data = jwtDecode(token || '')
    return data.id
  }

  get accessFiraData() {
    return localStorage.getItem('access_fira_data') === 'true'
  }

  createNewCaptchaCode() {
    return window.grecaptcha.execute('6Lc-RuEZAAAAAPybG1AjQcch0dZ9mXMw_OC0eyeM', { action: '' })
  }

  async login(username, password) {
    let recaptchaToken = null
    try {
      recaptchaToken = await this.createNewCaptchaCode()
    } catch {
      this.errors = 'invalid_recaptcha'
      return false
    }

    const responseLogin = await fetch(`${process.env.REACT_APP_GUILLOTINA}@login`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'X-VALIDATION-G': recaptchaToken,
      },
      body: JSON.stringify({
        login: username,
        password: password,
      }),
    })

    if (responseLogin.status !== 200) {
      this.errors = 'invalid_credentials'
      return false
    }
    const responseLoginData = await responseLogin.json()

    const respCanIdo = await fetch(
      `${process.env.REACT_APP_GUILLOTINA}${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}@canido?permissions=fm.AccedirERP,fm.AccedirDadesGeneralsFira`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${responseLoginData.token}`,
        },
      }
    )

    if (respCanIdo.status !== 200) {
      this.errors = 'invalid_permissions'
      return false
    }

    const canIdoData = await respCanIdo.json()

    if (!('fm.AccedirERP' in canIdoData) || !canIdoData['fm.AccedirERP']) {
      this.errors = 'invalid_permissions'
      return false
    }

    const permissionAccessFiraData = canIdoData['fm.AccedirDadesGeneralsFira']
    this.storeAuth(responseLoginData, permissionAccessFiraData)
    return true
  }

  storeAuth(data, permissionAccessFiraData) {
    localStorage.setItem('auth', data.token)
    localStorage.setItem('auth_expires', data.exp)
    localStorage.setItem('access_fira_data', permissionAccessFiraData)
    document.cookie = `auth_token=${data.token}; domain=${
      process.env.REACT_APP_DOMAIN
    }; path=/; expires=${new Date(data.exp * 1000)};`
  }

  cleanAuth() {
    localStorage.removeItem('auth')
    localStorage.removeItem('auth_expires')
    localStorage.removeItem('access_fira_data')
  }

  logout() {
    this.cleanAuth()
    document.cookie = `auth_token=; domain=${process.env.REACT_APP_DOMAIN}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }

  isExpired(expiration) {
    const now = new Date().getTime()
    if (new Date(expiration).getTime() > now) {
      return false
    }
    return true
  }
}
