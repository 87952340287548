import { get } from 'helpers/utils'
import { RenderRichText } from './RenderRichText'

const plain = ['string', 'number', 'boolean']

export function RenderField({ value, schema, Widget }) {
  if (value === null || value === undefined) return ''

  if (schema && schema.widget === 'textarea' && !Widget) {
    return <RenderRichText value={value} />
  }

  if (Widget) {
    return <Widget value={value} schema={schema} />
  }

  const type = typeof value
  if (plain.includes(type)) {
    return value
  } else if (type === 'object' && schema?.widget !== 'file') {
    if (Array.isArray(value)) {
      const subSchema = {
        type: 'object',
        properties: get(schema, 'items', {}),
        title: schema.title,
        widget: null,
      }

      return (
        <div className="columns is-12 is-multiline">
          {value.map((item) => (
            <div key={item} className="column is-half">
              <RenderField value={item} schema={subSchema} Widget={Widget} />
            </div>
          ))}
        </div>
      )
    }

    return Object.keys(value ?? {}).map((key) => (
      <FieldValue
        field={get(schema, `properties.${key}.title`, key)}
        schema={get(schema, `properties.${key}`, {})}
        value={value[key]}
        key={key}
      />
    ))
  }
  return <p>No render for {JSON.stringify(value)}</p>
}

const FieldValue = ({ field, value, schema }) => (
  <div className="field">
    <div className="label">{field}</div>
    <div className="value">
      <RenderField value={value} schema={schema} />
    </div>
  </div>
)
