import {
  BehaviorsView,
  Confirm,
  Icon,
  ItemModel,
  PropertiesButtonView,
  TraversalContext,
} from '@guillotinaweb/react-gmi'
import { Button, Divider } from 'antd'
import { PropertiesDetail, WorkflowLlotja } from 'components/Properties'
import { processResponse } from 'helpers/utils'
import React, { useEffect, useState } from 'react'
import { useSetState } from 'react-use'

import { ENS_TYPES } from 'helpers/constants'
import { ArtistsSection } from './Properties/artistsSection'
import { ManagerItem } from './Properties/managerItem'

const ignoreFiels = [
  'guillotina.behaviors.dublincore.IDublinCore',
  'guillotina.contrib.workflows.interfaces.IWorkflowBehavior',
  '__behaviors__',
  'type_name',
  'creation_date',
  'modification_date',
  'declaracio_acceptar',
  'representant',
]

export function PanelProperties() {
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const Ctx = React.useContext(TraversalContext)
  const modifyContent = Ctx.hasPerm('guillotina.ModifyContent')
  const [openSendEmails, setOpenSendEmails] = useState(false)
  const [loadingActions, setLoadingActions] = useState(false)

  const model = new ItemModel(Ctx.context)

  const handleSendEmailActivateEns = async () => {
    setLoadingActions(true)
    let processResult = {}
    try {
      const response = await Ctx.client.post(
        `${Ctx.url}${Ctx.client.cleanPathWithContainer(model.item['@id'])}/@sendEmailActivatedEns`
      )
      processResult = await processResponse(response, true)
    } catch (e) {
      console.error('Error', e)
      processResult = { isError: true, errorMessage: 'unhandled exception' }
    } finally {
      setLoadingActions(false)
      const { isError, errorMessage } = processResult
      if (!isError) {
        Ctx.flash(`Email send!`, 'success')
      } else {
        Ctx.flash(`Failed to send email!: ${errorMessage}`, 'danger')
      }
      Ctx.refresh()
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await Ctx.client.getTypeSchema(Ctx.path, model.type)
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  console.log('Ctx.context', Ctx.context)
  return (
    <div className="container">
      <div className="level">
        <div className="level-left">
          <h2 className="title is-size-4 is-primary">
            <Icon icon={model.icon} align="is-left" className="has-text-grey" />
            &nbsp;
            <span>{Ctx.context.title || Ctx.context['@name']}</span>
          </h2>
        </div>
        <div className="level-right">
          <PropertiesButtonView />
        </div>
      </div>
      <hr />
      {Ctx.context.review_state === 'publicat' && (
        <>
          <Divider orientation="left">Enviar email d&apos;activació</Divider>
          <Button
            className="mr-5"
            type="primary"
            disabled={loadingActions}
            onClick={() => {
              setOpenSendEmails(true)
            }}
          >
            Enviar
          </Button>
          {loadingActions && <div className="progress-line mt-2"></div>}
        </>
      )}

      {model?.item?.tipus === ENS_TYPES.ARTIST && (
        <ManagerItem schema={schema} ignoreFiels={ignoreFiels} />
      )}

      {schema && schema.data && !schema.loading && (
        <>
          <ArtistsSection />
          {modifyContent && <WorkflowLlotja />}
          <PropertiesDetail
            schema={schema.data}
            model={model.item}
            canModified={modifyContent}
            ignoreFields={ignoreFiels}
          />
          <BehaviorsView context={Ctx.context} schema={schema.data} />
        </>
      )}
      {openSendEmails && (
        <Confirm
          loading={loadingActions}
          onCancel={() => setOpenSendEmails(false)}
          onConfirm={handleSendEmailActivateEns}
          message="Estàs segur d'enviar l'email d'activació?"
        />
      )}
    </div>
  )
}
