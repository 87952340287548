import React from 'react'

import { MainMenu } from './menu'

import './layout.css'

const separatorCSS = {
  height: '100%',
  width: 20,
  backgroundColor: '#f3f3f3',
  right: 0,
  top: 0,
  zIndex: 30,
  position: 'absolute',
  minHeight: '100%',
}

export function Layout({ children, onLogout, isLogged }) {
  const [isMenuOpened, setMenuOpened] = React.useState(true)

  return (
    <section className={`section main`}>
      <div className="container columns">
        {isLogged && (
          <div
            className="column is-one-quarter sidebar"
            style={{
              position: 'relative',
              padding: 0,
              width: isMenuOpened ? '20%' : 20,
            }}
          >
            {isMenuOpened && <MainMenu onLogout={onLogout} />}
            <div
              className="separator"
              style={separatorCSS}
              onClick={() => {
                setMenuOpened(!!!isMenuOpened)
              }}
            ></div>
          </div>
        )}

        <div className="column main-content" style={{ width: '100%', overflow: 'scroll' }}>
          {children}
        </div>
      </div>
      <div className="footer-content"> ERP Fira Mediterrania v2.56.1 </div>
    </section>
  )
}
