import { PanelActions, TabsPanel, useTraversal } from '@guillotinaweb/react-gmi'
import { ContextToolbar } from 'components/ContextToolbar'
import { PanelProperties } from 'components/Panels/CategoriaPlaImpulsMusica'
import { PanelItems } from 'components/Panels/Items'
import { PanelPermissions } from 'components/Panels/Permissions'

const tabs = {
  Items: PanelItems,
  Properties: PanelProperties,
  Permissions: PanelPermissions,
  Actions: PanelActions,
}

const tabsPermissions = {
  Items: 'guillotina.ViewContent',
  Properties: 'guillotina.ViewContent',
  Permissions: 'guillotina.SeePermissions',
}

export function CategoriaPlaImpulsMusicaView(props) {
  const traversal = useTraversal()
  const calculated = traversal.filterTabs(tabs, tabsPermissions)
  return (
    <TabsPanel
      tabs={calculated}
      currentTab="Items"
      editableTabs={[]}
      rightToolbar={<ContextToolbar {...props} />}
      {...props}
    />
  )
}
